.browseSection{
    display: flex;
    gap: 100px;
}

.footerTag{
    gap: 20px;
    @media(max-width:900px){
        padding-right: 2rem;
    }
}

.footerTagContainer {
    position: absolute;
    width: 100%;
    border-top: 0.5px solid; 
    border-image-source: linear-gradient(90deg, #44BFD1 0%, #31BCCD 100%); 
    border-image-slice: 1; 
    @media (max-width:900px){
        position: inherit;
    }
   
}

.mainfooter{
    position: absolute;
    width: 100%;
    @media (max-width:900px){
        position: inherit;
        p{
            font-size: 12px;
        }
    }

   
}

.socialcontainer{
    @media (max-width:900px){
       align-items: flex-start;
       padding: 1rem;
    }
}

.flag{
    @media (max-width:900px){
        margin-left: 0px;
    }
}

.flag2{
    @media (max-width:900px){
        margin-left: 5px;
    }
}

.copyrightTextmobile{
    @media (max-width:900px){
       display: none;
    }
}

.copyrightTextdesktop{
    display: none;
    @media (max-width:900px){
        display: block;
    }
}